import { feedbackIntegration, init, replayIntegration } from '@sentry/astro';

const feedback = feedbackIntegration({
	colorScheme: 'system',
	showBranding: false,
	autoInject: false,
});

init({
	dsn: 'https://cddd896525205da9718d1355c316fd00@o4507645464674304.ingest.de.sentry.io/4507645468475472',

	integrations: [
		replayIntegration({
			maskAllText: true,
			blockAllMedia: true,
		}),
		feedback,
	],
});

feedback.attachTo(document.getElementById('feedbackBtn'));
